.dop-datepicker {
  @apply flex items-center gap-1 p-1;

  &:disabled,
  &[disabled],
  &:global(.disabled) {
    cursor: not-allowed;
    color: var(--dop-color-text-basic-disabled, #c5c7ca);
    border: 1px solid var(--dop-color-field-border-disabled, #e1e3e6);
    background-color: var(--dop-color-field-bg-disabled, #eaecef);
  }

  &.bordered {
    @apply border rounded-md;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  & input {
    /* @apply text-2xs; */
    width: 100%;
    color: inherit;
    background-color: inherit;
  }
  & input:disabled,
  & input[disabled],
  & input.disabled {
    cursor: not-allowed;
  }
}

.react-datepicker {
  padding: 4px;
  border: 1px solid #c1c1c1 !important;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.custom-react-datepicker__label-span {
  font-size: 15px;
  width: 100%;
  color: #2b2b2b;
}
.react-datepicker {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border: none !important;
  border-radius: 4px;
}
.custom-react-datepicker__select-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 198px;
  height: 36px;
}
.custom-react-datepicker__select-wrapper button {
  background-color: transparent;
  border: none;
}
.custom-react-datepicker__select-item {
  display: flex;
  font-display: row;
  justify-content: space-between;
  align-items: center;
  width: 64px;
  background-color: black;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.react-datepicker__triangle {
  display: none;
}
.react-datepicker__day--selected {
  width: 28px;
  height: 28px;
  background-color: black !important;
  color: white !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.react-datepicker__month {
  display: flex;
  margin: 0px !important;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.react-datepicker__header {
  background-color: #ffffff;
  padding-bottom: 0;
}

.react-datepicker__day--keyboard-selected {
  background-color: white;
}

.react-datepicker__day-name:nth-child(1),
.react-datepicker__day:nth-child(1) {
  color: red; /* 일요일 날짜*/
}

.react-datepicker__day-names,
.react-datepicker__day {
  width: 28px !important;
  text-align: center;
  font-size: 12px;
  line-height: 28px !important;
  color: black;
}
.react-datepicker__day:hover {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.react-datepicker__day--today {
  background-color: #ddd;
  color: black;
  font-weight: normal;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.react-datepicker__day--disabled {
  color: #ddd !important;
}

.react-datepicker__day--disabled {
  color: #ddd !important;
  background-color: transparent !important;
}
